import * as React from 'react';

import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  hint?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <div
        className={cn(
          'border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-2 text-sm shadow-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
      >
        <input
          type={type}
          className="placeholder:text-muted-foreground w-full bg-transparent px-3 py-2 focus-visible:outline-none"
          ref={ref}
          {...props}
        />
        {props.hint && <p className="ml-auto mr-4">{props.hint}</p>}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
